import request from "./request";
import { getChatId } from '@/utils/chats';

export const removeFromChat = (uid: string, Jid: string, kind: string) => {
    return request('post', '/api/chat/message/delete', {uid, Jid, kind})
        .then(data => {
            return data.data
        })
}

export const removeFromGroup = (uid: string, activeChatJid: string) => {
  return request('post', '/api/group/message/delete', {uid, jid: activeChatJid})
    .then(data => {
      return data.data
    })
}

export const readMessage = (uid: string, chatJid: string) => {
  return request(
    'post',
    '/api/user/account/message/read',
    {
      chatJid,
      uid
    }
  ).then(({data}) => data)
}

export const readAllMessageInChat = (chatJid: string) => {
  return request(
    'post',
    '/api/user/account/group/all-read',
    {
      groupUid: getChatId(chatJid)
    }
  )
}
