import {
  BaseSyntheticEvent,
  ElementType,
  ForwardedRef,
  forwardRef,
  FunctionComponent,
  LegacyRef,
  UIEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { IMessage, IMessageStatus, update as updateMessages } from '@/store/messages/messages';
import {getLastTime} from '@/utils/dateutils';
import TextMessage from './TextMessage/TextMessage';
import ImageMessage from './ImageMessage/ImageMessage';
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {getUser} from '@/store/user/user';
import styles from './ChatMessage.module.scss'
import messagesStyles from '../ChatMessages/ChatMessages.module.scss'
import classNames from 'classnames';
import Avatar from '@/components/Avatar/Avatar';
import { getActiveChat, getActiveChatId, getChat } from '@/store/chats/chats';
import FileMessage from '@/components/Chat/ChatMessage/FileMessage/FileMessage';
import {LongPressCallback, LongPressEventType, useLongPress} from 'use-long-press';
import {isMouseEvent, isTouchEvent} from '@/utils/events';
import AudioMessage from '@/components/Chat/ChatMessage/AudioMessage/AudioMessage';
import StickerMessage from '@/components/Chat/ChatMessage/StickerMessage/StickerMessage';
import Messages from '@/services/Messages';
import {isRoomMessage} from '@/utils/chats';
import useGetNames from '@/hooks/useGetNames';
import VideoMessage from '@/components/Chat/ChatMessage/VideoMessage/VideoMessage';
import connection from '@/services/Connection/Connection';
import CallMessage from '@/components/Chat/ChatMessage/CallMessage/CallMessage'
import { changeNewsViewed, INews, isNews } from '@/store/news/news'
import NewsMessage from '@/components/Chat/ChatMessage/NewsMessage/NewsMessage'
import { readNews } from '@/api/news'
import { getLang } from '@/store/language/language'
import { readMessage } from '@/api/message';
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';

interface IChatMessageProps {
  message: IMessage | INews,
  TagName?: ElementType
  onContextMenu?: (e: ModifiedTouchEvent) => void
}

export interface IMessageComponent {
  msg: IMessage | INews,
  incoming: boolean,
}

export interface ModifiedTouchEvent extends BaseSyntheticEvent {
  pageX: number
  pageY: number
}

const ChatMessage = forwardRef(
  ({message, TagName = 'div', onContextMenu}: IChatMessageProps, ref: ForwardedRef<HTMLElement>) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(getUser)
    const lang = useAppSelector(getLang)
    const observerCb = useRef<IntersectionObserverCallback>(() => {});
    let from = ''
    let to = ''
    let thread: string | undefined
    let status: IMessageStatus | undefined
    if (!isNews(message)) {
      from = message.from
      to = message.to
      from = message.from
      to = message.to
      thread = message.thread
      status = message.status
    } else {
      status = message.viewed ? 'displayed' : 'received'
    }
    const contact = useAppSelector(getChat(from))
    const chatJid = useAppSelector(getActiveChatId)
    const activeChat = useAppSelector(getActiveChat)
    const [avatar, setAvatar] = useState<string>()
    const [incoming, setIncoming] = useState(from !== user?.$jid)
    const visibleObserver = useRef<IntersectionObserver>()
    const messageRef = useRef<HTMLElement | null>(null)
    const {fromName} = useGetNames({message, incoming})

    useEffect(() => {
      setIncoming(from !== user?.$jid)
    }, [from, user?.$jid])

    observerCb.current = useCallback((entries) => {
      if (entries[0]?.isIntersecting) {
        const isNewsMessage = isNews(message)
        if (!isNewsMessage && !isRoomMessage(message)) {
          const msg = Messages.Messages.createNotifyMessageDisplayed({
            from: to,
            to: from,
            messageId: message.id,
            timestamp: message.timestamp,
            thread: thread,
          })
          connection.sendStrophe(msg)
        }
        if (isNewsMessage){
          readNews({
            lang,
            id: message.announcementId
          })
          dispatch(changeNewsViewed({
            newsId: message.announcementId
          }))
        } else {
          if (isRoomMessage(message) && chatJid) {
            readMessage(message.id, chatJid)
          }
          dispatch(updateMessages({
            jid: chatJid || '',
            messages: [{
              ...message,
              status: 'displayed',
            }],
          }))
        }
      }
    }, [chatJid, message, dispatch, from, lang, thread, to])

    useEffect(() => {
      let cb: IntersectionObserverCallback
      const element = messageRef.current
      if (element
        && incoming
        && status !== 'displayed'
      ) {
        if (!visibleObserver.current) {
          const options: IntersectionObserverInit = {
            root: document.querySelector(messagesStyles.box),
          }
          cb = (entries, observer) => {
            observerCb.current(entries, observer)
          }
          visibleObserver.current = new IntersectionObserver(cb, options)
        }
        visibleObserver.current?.observe(element)
      }
      return () => {
        if (element) {
          visibleObserver.current?.unobserve(element)
        }
      }
    }, [incoming, status])

    useEffect(() => {
      if (isNews(message)) {
        return
      }
      setAvatar(contact?.vcard?.thumbnail)
    }, [contact, from, message])

    let Component: FunctionComponent<IMessageComponent> | undefined
    switch (message.type) {
      case 'text':
        Component = TextMessage;
        break;
      case 'image':
        Component = ImageMessage;
        break
      case 'file':
        Component = FileMessage
        break
      case 'audio':
        Component = AudioMessage
        break
      case 'video':
        Component = VideoMessage
        break
      case 'sticker':
        Component = StickerMessage
        break
      case "call":
        Component = CallMessage
        break
      case "news":
        Component = NewsMessage
        break
    }

    const handleContextMenu = useCallback((e: ModifiedTouchEvent) => {
      if (e.cancelable && typeof e.preventDefault === 'function') {
        e.preventDefault();
      }
      onContextMenu?.(e)
    }, [onContextMenu])

    const longPress: LongPressCallback = useCallback((event) => {
      if (!isTouchEvent(event)) {
        return
      }
      const newEvent: ModifiedTouchEvent = {
        ...event,
        pageX: 0,
        pageY: 0,
      }
      if (event.type === 'touchstart') {
        newEvent.pageX = event.changedTouches[0]?.pageX + 10
        newEvent.pageY = event.changedTouches[0]?.pageY + 10
      }
      handleContextMenu(newEvent)
    }, [handleContextMenu])

    const bind = useLongPress(longPress, {
      cancelOnMovement: 5,
      detect: LongPressEventType.Touch
    })

    const handleContextMenuWithCheck = (e: UIEvent) => {
      if (isMouseEvent(e)) {
        handleContextMenu(e)
      }
    }

    const handleRef: LegacyRef<HTMLElement> = (e) => {
      if (typeof ref === 'function') {
        ref(e)
      } else if (ref && typeof ref === 'object') {
        ref.current = e
      }
      messageRef.current = e
    }

    if (!Component) {
      return <TagName ref={handleRef}>
        {`${message.type} (${status}) (${getLastTime(message.timestamp)}) (${from})`}
      </TagName>
    }

    return <TagName
      ref={handleRef}
      className={classNames(styles.message, incoming && styles.incoming)}
      onContextMenu={handleContextMenuWithCheck}
      {...bind()}
    >
      {incoming && activeChat?.type === 'groupchat' &&
        <Avatar
          className={styles.avatar}
          src={avatar}
          name={fromName}
        />
      }
      <Component
        msg={message}
        incoming={incoming}
      />
      <IconButton
        className={classNames(styles.contextMenuBtn, incoming && styles.incoming)}
        onClick={handleContextMenuWithCheck}
      >
        <i className="chat-dots"/>
      </IconButton>
    </TagName>

  })

export default ChatMessage
