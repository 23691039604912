import {
  useCallback,
  useEffect,
  useRef,
  useState,
  UIEvent,
  ComponentProps,
  createContext,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  getMessages,
  IMessage,
  ITextMessage,
  messagesWasFullLoaded,
  setReply,
  update,
  remove,
  IMessageDate,
  ShowedMessage,
  updateWithBreak,
  isBreakMessage,
  isIMessageDate,
  readMessagesInChat,
} from '@/store/messages/messages'
import { getMessages as getHistoryMessages } from '@/api/chats'
import {
  useAppDispatch,
  useAppSelector
} from '@/hooks/appHook';
import {
  Chat,
  getActiveChatId,
  getChat,
  IGroupChatMember,
  updateChat
} from '@/store/chats/chats';
import ChatMessage, { ModifiedTouchEvent } from '@/components/Chat/ChatMessage/ChatMessage';
import styles from './ChatMessages.module.scss'
import messageStyles from '../ChatMessage/ChatMessage.module.scss'
import { Scrollbars } from 'react-custom-scrollbars-2';
import { getUser } from '@/store/user/user';
import {
  checkAdmin,
  checkOwner,
  getChatId
} from '@/utils/chats';
import { fromHistoryToMessage } from '@/utils/messages';
import useContextMenu from '@/components/Menu/ContextMenu/useContextMenu';
import ContextMenuList, {
  ContextMenuProps,
  IContextMenuList
} from '@/components/Menu/ContextMenu/ContextMenuList';
import { useIntl } from 'react-intl';
import Forward from '@/components/Modal/ForwardModal/Forward';
import useMessage from '@/hooks/useMessage';
import { isTextMessage } from '@/components/Chat/ChatMessage/TextMessage/TextMessage';
import Confirm from '@/components/Modal/Confirm/Confirm';
import { translation } from '@/api/translation';
import {
  readAllMessageInChat,
  removeFromChat,
  removeFromGroup
} from '@/api/message';
import { createPubSub } from '@inficen/react-events';
import { getLang } from '@/store/language/language';
import {
  getMessageScroll,
  updateMessageScroll
} from '@/store/scroll/scroll';
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';
import classNames from 'classnames';
import DateMessage, { isDateMessage } from '@/components/Chat/ChatMessage/DateMessage/DateMessage';
import {
  isFileMessage,
  loadFileNotify
} from '@/components/Chat/ChatMessage/FileMessage/FileMessage';
import useModalSimple from '@/hooks/useModalSimple';
import { getOrCreateArray } from '@/utils/array';
import BreakMessage from '@/components/Chat/ChatMessage/BreakMessage/BreakMessage.';
import { CustomEvents } from '@/interfaces/general';
import { getScreenHeight } from '@/store/screenSize/screenSize';
import { ErrorFactory } from '@/services/errorFactory';
import { ReactComponent as TranslateImage } from '@/images/icons/translate.svg';
import * as linkify from 'linkifyjs';
import {
  pin,
  unPin
} from '@/api/pin';
import {
  isAudioMessage,
  loadAudioNotify
} from '@/components/Chat/ChatMessage/AudioMessage/AudioMessage';
import { copyTextToClipboard } from '@/utils/app';
import {
  isVideoMessage,
  loadVideoNotify
} from '@/components/Chat/ChatMessage/VideoMessage/VideoMessage';
import {
  getActiveNewsChat,
  INews,
  isNews
} from '@/store/news/news'
import { isCallMessage } from '@/components/Chat/ChatMessage/CallMessage/CallMessage'

const SCROLL_BTN_VISIBLE_OFFSET = 40
const FIND_MESSAGE_TIMER = 5000
const LOAD_AFTER_FINDING_LIMIT = 15
const PROTECTION_SCROLL_TIME = 1000
const HIGHLIGHT_MESSAGE_PADDING = 2

type Event = {
  name: string,
  payload: {
    message: IMessage
  }
}

const { publish, useSubscribe } = createPubSub<Event>()

const FORWARD_EVENT = 'forwardMessage'
const NAME_EVENT = 'CHAT_MESSAGES'

interface ForwardMessageProps {
  message: IMessage
}

export const forwardMessage = ({ message }: ForwardMessageProps) => {
  const nameEvent: string = `${NAME_EVENT}/${FORWARD_EVENT}`
  publish(nameEvent, { message })
}

const LOAD_MORE_COUNT = 10

const ACTIONS = {
  DELETE: 'delete',
  FORWARD: 'forward',
  REPLY: 'reply',
  TRANSLATION: 'translation',
  LOAD: 'load',
  PIN: 'pin',
  UNPIN: 'unpin',
  COPY_TEXT: 'copyText',
  COPY_LINK: 'copyLink',
}

const contextMenuList: {
  list: IContextMenuList[]
} = {
  list: [
    { text: 'message_reply', action: ACTIONS.REPLY, icon: 'chat-reply' },
    { text: 'CHAT.FORWARD_MESSAGE', action: ACTIONS.FORWARD, icon: 'chat-forward' },
  ],
}

interface ChatMessagesProps {
  className?: string
}

interface FindMessage {
  messageId: string
  timerId: NodeJS.Timer
  resolve: () => void
}

export const ChatMessagesContext = createContext({
  disableLoading: false,
  onReplyClick: (msg: IMessage) => Promise.resolve(),
})

const highlightMessage = (message?: HTMLElement) => {
  if (!message) {
    return
  }
  const box = document.documentElement.querySelector('.' + styles.messages)
  if (!box) {
    return;
  }
  const oldAnimationElement = message.querySelector('.' + styles.highlight)
  oldAnimationElement?.remove()
  const div = document.createElement('div')
  div.classList.add(styles.highlight)
  div.style.height = message.offsetHeight + HIGHLIGHT_MESSAGE_PADDING * 2 + 'px'
  div.style.width = box.clientWidth - 20 + 'px'
  div.style.top = -HIGHLIGHT_MESSAGE_PADDING + 'px'
  if (message.classList.contains(messageStyles.incoming)) {
    div.style.left = -10 + 'px'
  } else {
    div.style.right = -20 - HIGHLIGHT_MESSAGE_PADDING + 'px'
  }
  message.classList.add(styles.highlightMessage)
  message.insertAdjacentElement('afterbegin', div)

  const disableAnimation = () => {
    div.remove()
    message.classList.remove(styles.highlightMessage)
  }
  div.addEventListener('animationend', disableAnimation)
}

export interface IChatMessages {
  replyClick: (msg: IMessage) => void
}

const ChatMessages = forwardRef<IChatMessages, ChatMessagesProps>(({ className }: ChatMessagesProps, ref) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const screenHeight = useAppSelector(getScreenHeight)
  const activeChatJid = useAppSelector(getActiveChatId) || ''
  const activeChat = useAppSelector(getChat(activeChatJid))
  const newsChat = useAppSelector(getActiveNewsChat)
  const chatMessagesScroll = useAppSelector(getMessageScroll(newsChat ? newsChat.id : activeChatJid))
  const messages = useAppSelector(getMessages(activeChatJid))
  const [chatMessages, setChatMessages] = useState<(ShowedMessage | INews)[]>([])
  const [chatMessagesWithDate, setChatMessagesWithDate] = useState<(ShowedMessage | IMessageDate | INews)[]>([])
  const [safeUpdatedMessages, setSafeUpdatedMessages] = useState<(ShowedMessage | IMessageDate | INews)[]>([])
  const [forwardedMessage, setForwardedMessage] = useState<IMessage | null>(null)
  const [scrollButtonVisible, setScrollButtonVisible] = useState(false)
  const [confirmProps, setConfirmProps] = useState<ComponentProps<typeof Confirm>>({})
  const [disableLoading, setDisableLoading] = useState(false)
  const onScrollStopRef = useRef<() => void>()
  const scrollBarRef = useRef<Scrollbars | null>(null)
  const messagesRef = useRef<(HTMLElement | null)[]>([])
  const messageListRef = useRef<HTMLUListElement | null>(null)
  const messageListSizeObserver = useRef<ResizeObserver>()
  const cbMessageListResize = useRef<ResizeObserverCallback>();
  const chatOpenedNotFirst = useRef<{ [key: string]: boolean }>({})
  const scrollBottom = useRef(0)
  const scrollHeight = useRef(0)
  const isScrollingNow = useRef(false)
  const isFirstScrolling = useRef(false);
  const scrollTimerProtection = useRef<NodeJS.Timer>()
  const messagesLoading = useRef<Set<string>>(new Set())
  const findMessage = useRef<FindMessage>()
  const { formatMessage } = useIntl()
  const { forwardMessage } = useMessage()
  const lang = useAppSelector(getLang)
  const [members, setMembers] = useState(getOrCreateArray<IGroupChatMember>(activeChat?.members))
  const [isOwner, setIsOwner] = useState(checkOwner(user?.$jid || '', members))
  const [isAdmin, setIsAdmin] = useState(checkAdmin(user?.$jid || '', members))
  const scrollBottomAfterAddRef = useRef(false)
  const savedScrollChat = useRef<string>();

  // useEffect не успевает за изменением scrollBottom,
  // поэтому вынес в такое условие
  const currentChatJid = newsChat ? newsChat.id : activeChatJid
  if (savedScrollChat.current !== currentChatJid) {
    if (savedScrollChat.current != null) {
      dispatch(updateMessageScroll({
        jid: savedScrollChat.current,
        value: scrollBottom.current
      }))
    }
    savedScrollChat.current = currentChatJid
    if (chatOpenedNotFirst.current[currentChatJid] == null && chatMessagesScroll != null) {
      chatOpenedNotFirst.current[currentChatJid] = true
    }
  }

  useEffect(() => {
    return () => {
      if (savedScrollChat.current != null) {
        dispatch(updateMessageScroll({
          jid: savedScrollChat.current,
          value: scrollBottom.current
        }))
      }
    }
  }, [dispatch]);

  if (!messageListSizeObserver.current) {
    messageListSizeObserver.current = new ResizeObserver((entries, observer) => {
      cbMessageListResize.current?.(entries, observer)
    })
  }

  const scrollToBottom = useCallback(() => {
    const box = scrollBarRef.current
    const container = box?.container.querySelector('.' + styles.messages)
    const parent = container?.parentElement
    if (parent) {
      const offset = parent.scrollTop + parent.clientHeight
      if (offset === container.scrollHeight) {
        return
      }
      setDisableLoading(true)
      parent.scrollTo({
        top: container.scrollHeight,
        behavior: 'smooth',
      })
      isScrollingNow.current = true
      clearTimeout(scrollTimerProtection.current)
      scrollTimerProtection.current = setTimeout(() => {
        onScrollStopRef.current?.()
      }, PROTECTION_SCROLL_TIME)
    }
  }, [])

  const scrollToBottomAndReadMessages = () => {
    dispatch(readMessagesInChat(activeChatJid))
    readAllMessageInChat(activeChatJid)
    scrollToBottom()
  }

  const updateSafeMessages = useCallback(() => {
    if (isScrollingNow.current || safeUpdatedMessages === chatMessagesWithDate) {
      return
    }
    setSafeUpdatedMessages(chatMessagesWithDate)
  }, [safeUpdatedMessages, chatMessagesWithDate])

  const checkScrollBtnVisible = useCallback(() => {
    if (scrollBottom.current > SCROLL_BTN_VISIBLE_OFFSET) {
      setScrollButtonVisible(true)
    } else {
      setScrollButtonVisible(false)
    }
  }, []);

  onScrollStopRef.current = () => {
    if (isFirstScrolling.current) {
      scrollBottom.current = getScrollBottom()
      isFirstScrolling.current = false
      checkScrollBtnVisible()
      if (chatMessagesScroll == null && savedScrollChat.current) {
        dispatch(updateMessageScroll({
          jid: savedScrollChat.current,
          value: scrollBottom.current
        }))
      }
    }
    if (disableLoading) {
      setDisableLoading(false)
    }
    isScrollingNow.current = false
    updateSafeMessages()
  }

  const onScrollStart = () => {
    clearTimeout(scrollTimerProtection.current)
    isScrollingNow.current = true
  }

  useEffect(() => {
    updateSafeMessages()
  }, [updateSafeMessages])

  const handleForwardMessage = useCallback((chats: Chat[]) => {
    if (forwardedMessage) {
      forwardMessage(forwardedMessage, chats)
    }
  }, [forwardedMessage, forwardMessage])

  const onCloseForwardModal = useCallback(() => {
    setForwardedMessage(null)
  }, [])

  const { show: showForwardModal, hide: hideForwardModal, visible: visibleForwardModal } = useModalSimple({
    onHide: onCloseForwardModal,
  })
  const { show: showConfirmModal, hide: hideConfirmModal, visible: visibleConfirmModal } = useModalSimple()

  const isMineMessage = (msg: IMessage) => {
    return msg.from === user?.$jid
  }

  const isGroupChat = () => {
    return activeChat?.type === 'groupchat'
  }

  const isPrivateChat = () => {
    return activeChat?.type === 'chat'
  }

  const getConfirmTitle = () => {
    if (isGroupChat()) {
      return 'OK'
    }
    return formatMessage({ id: 'CHAT.DELETE_MESSAGE_FOR_ME' })
  }

  const getCancelTitle = (msg: IMessage) => {
    if (isMineMessage(msg) && !isGroupChat()) {
      return formatMessage({ id: 'CHAT.DELETE_MESSAGE_FOR_EVERYONE' })
    }
    return 'Cancel'
  }

  const handleDeleteMessageFromGroup = (msg: IMessage) => {
    removeFromGroup(msg.id, activeChatJid).then(() => {
    })
  }

  const handleDeleteMessageFromChat = (msg: IMessage, kind: string) => {
    removeFromChat(msg.id, msg.to, kind).then((result) => {
      if (result?.status === 'ok' && !!user?.$jid) {
        dispatch(remove({ jid: activeChatJid, message: msg }))
      }
    })
  }

  const handleDeleteMessageConfirmProcess = (msg: IMessage) => {
    if (isGroupChat()) {
      handleDeleteMessageFromGroup(msg)
    } else {
      handleDeleteMessageFromChat(msg, 'for_me')
    }
    hideConfirmModal()
  }

  const handleDeleteMessageCancelProcess = (msg: IMessage) => {
    if (isMineMessage(msg) && !isGroupChat()) {
      handleDeleteMessageFromChat(msg, 'everyone')
    }
    hideConfirmModal()
  }

  const keyValLang = {
    en: 'en',
    jp: 'ja',
    kr: 'ko',
    cn: 'zh-Hans',
    tc: 'zh-Hant',
  };

  const handleTranslationMessage = (msg: ITextMessage) => {
    const newMsg = { ...msg, isTranslating: true }
    dispatch(update({
      jid: activeChatJid,
      messages: [newMsg],
    }))
    translation(keyValLang[lang], msg.text).then((result) => {
      const newMsgResp = { ...msg, textTranslate: result.text, isTranslating: false, isTranslated: true }
      dispatch(update({
        jid: activeChatJid,
        messages: [newMsgResp],
      }))
    })
  }

  const handlePin = (msg: IMessage) => {
    if (activeChat) {
      let text = ''
      if (isTextMessage(msg)) {
        text = msg.text
      } else if (isFileMessage(msg)) {
        text = msg.file.name
      }
      pin(msg.id, activeChat?.$jid, isGroupChat() ? 'groupchat' : 'chat').then(() => {
        dispatch(updateChat({
          chatJid: activeChat?.$jid, options: {
            pin: {
              uid: msg.id,
              text: text,
              type: msg.type,
              timestamp: msg.timestamp,
              visible: true,
            },
          },
        }))
      })
    }
  }

  const handleUnPin = (msg: IMessage) => {
    if (activeChat) {
      unPin(msg.id, activeChat?.$jid, isGroupChat() ? 'groupchat' : 'chat').then(() => {
        dispatch(updateChat({
          chatJid: activeChat?.$jid, options: {
            pin: null,
          },
        }))
      })
    }
  }

  const onActionForwardMessage = useCallback((msg: IMessage) => {
    if (msg) {
      setForwardedMessage(msg)
      showForwardModal()
    }
  }, [showForwardModal])

  useSubscribe(`${NAME_EVENT}/${FORWARD_EVENT}`, ({ message }) => {
    onActionForwardMessage(message)
  })

  const onClickContextMenu = (action: string, msg: IMessage) => {
    switch (action) {
      case ACTIONS.REPLY:
        dispatch(setReply({ jid: activeChatJid || '', replyMessage: msg }))
        break;
      case ACTIONS.FORWARD:
        onActionForwardMessage(msg)
        break
      case ACTIONS.TRANSLATION:
        if (isTextMessage(msg)) {
          handleTranslationMessage(msg)
        }
        break;
      case ACTIONS.DELETE:
        setConfirmProps({
          title: formatMessage({
            id: activeChat?.type === 'groupchat'
              ? 'GROUP_CHAT.MESSAGE_DELETE_ASK'
              : 'CHAT.MESSAGE_DELETE_ASK',
          }),
          onConfirm: () => {
            handleDeleteMessageConfirmProcess(msg)
          },
          onCancel: () => {
            handleDeleteMessageCancelProcess(msg)
          },
          confirmTitle: getConfirmTitle(),
          cancelTitle: getCancelTitle(msg),
        })
        showConfirmModal()
        break;
      case ACTIONS.LOAD:
        if (isFileMessage(msg)) {
          loadFileNotify({
            messageId: msg.id,
            force: true,
          })
        } else if (isAudioMessage(msg)) {
          loadAudioNotify({ messageId: msg.id })
        } else if (isVideoMessage(msg)) {
          loadVideoNotify({ messageId: msg.id })
        }
        break;
      case ACTIONS.PIN:
        handlePin(msg)
        break;
      case ACTIONS.UNPIN:
        handleUnPin(msg)
        break;
      case ACTIONS.COPY_TEXT:
        if (!isTextMessage(msg)) {
          break
        }
        copyTextToClipboard(msg.text)
        break;
      case ACTIONS.COPY_LINK:
        if (!isTextMessage(msg)) {
          break
        }
        const links = linkify.find(msg.text)
        if (!links.length) {
          return
        }
        copyTextToClipboard(links[0].href)
        break
      default:
        throw ErrorFactory.createActionNotImplemented(action)
    }
  }

  const { show: showContextMenu, ContextMenu } = useContextMenu<ContextMenuProps>({
    Component: ContextMenuList,
    componentProps: {
      list: [],
    },
  })

  const getScrollBottom = () => {
    const container = scrollBarRef.current
    if (container) {
      return container.getScrollHeight() - container.getClientHeight() - container.getScrollTop()
    }
    return 0
  }

  useEffect(() => {
    const container = scrollBarRef.current
    container?.scrollTop(container.getScrollHeight() - container.getClientHeight() - scrollBottom.current)
  }, [screenHeight])

  cbMessageListResize.current = useCallback(() => {
    const container = scrollBarRef.current
    scrollHeight.current = container?.getScrollHeight() || 0
    if (isFirstScrolling.current) {
      return
    }
    const jid = newsChat ? newsChat.id : activeChatJid
    const bottom = getScrollBottom()
    if (scrollBottomAfterAddRef.current) {
      scrollBottomAfterAddRef.current = false
      scrollToBottom()
    } else if (bottom !== scrollBottom.current
      && container
      && chatOpenedNotFirst.current[jid]
    ) {
      const offset = container.getScrollHeight() - container.getClientHeight() - scrollBottom.current
      container.scrollTop(offset)
    }
    if (!container || container.getScrollHeight() <= container.getClientHeight()) {
      setScrollButtonVisible(false)
    }
  }, [activeChatJid, newsChat, scrollToBottom])

  const scrollToFirstUnreadMessage = useCallback((messages: (ShowedMessage | INews | IMessageDate)[]) => {
    const jid = newsChat ? newsChat.id : activeChatJid
    if (chatOpenedNotFirst.current[jid]) {
      return
    }
    const firstUnreadMessageIndex = messages
      .findIndex(message => !isIMessageDate(message) && !isBreakMessage(message) && (isNews(message)
        ? !message.viewed
        : message.from !== user?.$jid && message.status !== 'displayed'))
    const htmlMessage = ~firstUnreadMessageIndex ?
      messagesRef.current[firstUnreadMessageIndex] : null
    if (htmlMessage || (messages.length && firstUnreadMessageIndex < 0)) {
      chatOpenedNotFirst.current[jid] = true
    }
    if (htmlMessage) {
      htmlMessage?.scrollIntoView({ block: 'end' })
      isFirstScrolling.current = true
    } else {
      scrollBarRef.current?.scrollToBottom()
      scrollBottom.current = 0
    }
  }, [user?.$jid, newsChat, activeChatJid])

  useEffect(() => {
    const container = messageListRef.current
    const observer = messageListSizeObserver.current
    if (!container || !observer) {
      return
    }
    observer.observe(container)

    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    if (!newsChat) {
      setChatMessages(messages?.messages || [])
      messagesRef.current.length = messages?.messages.length || 0
    }

  }, [messages, newsChat])

  useEffect(() => {
    if (newsChat) {
      setChatMessages(newsChat.news)
      messagesRef.current.length = newsChat.news.length
    }
  }, [messages, newsChat]);

  useEffect(() => {
    if (chatMessagesScroll == null) {
      return
    }
    scrollBottom.current = chatMessagesScroll
    const container = scrollBarRef.current
    if (container) {
      container.scrollTop(container.getScrollHeight() - container.getClientHeight() - chatMessagesScroll)
    }
  }, [chatMessagesScroll])

  useEffect(() => {
    const jid = newsChat ? newsChat.id : activeChatJid
    if (!chatOpenedNotFirst.current[jid] || !safeUpdatedMessages.length) {
      return
    }
    scrollToFirstUnreadMessage(safeUpdatedMessages)
  }, [safeUpdatedMessages, activeChatJid, scrollToFirstUnreadMessage, newsChat])

  const scrollBottomAfterAdd = useCallback(() => {
    scrollBottomAfterAddRef.current = true
  }, []);

  useEffect(() => {
    window.addEventListener(CustomEvents.MESSAGE_SENT_TO_CURRENT_CHAT, scrollBottomAfterAdd)

    return () => {
      window.removeEventListener(CustomEvents.MESSAGE_SENT_TO_CURRENT_CHAT, scrollBottomAfterAdd)
    }
  }, [scrollBottomAfterAdd])

  const loadMore = () => {
    if (messagesLoading.current.has(activeChatJid)
      || messages.isFullLoaded
    ) {
      return
    }
    messagesLoading.current.add(activeChatJid)

    getHistoryMessages({
      id: getChatId(activeChatJid),
      isRoom: activeChat?.type === 'groupchat',
      end: messages.messages[0].timestamp,
      limit: LOAD_MORE_COUNT,
    })
      .then(data => {
        dispatch(update({
          jid: activeChatJid,
          messages: data.map(item => fromHistoryToMessage(item)),
        }))
        if (data.length < LOAD_MORE_COUNT) {
          dispatch(messagesWasFullLoaded({
              jid: activeChatJid,
              value: true,
            },
          ))
        }

      })
      .finally(() => {
        messagesLoading.current.delete(activeChatJid)
      })
  }

  const onContextMenu = (e: ModifiedTouchEvent, msg: IMessage) => {
    const position = {
      x: e.pageX,
      y: e.pageY,
    }
    let list = contextMenuList.list.map(item => ({
      ...item,
      text: formatMessage({ id: item.text }),
    }))
    if (isCallMessage(msg)) {
      list = list.filter(item => item.action !== ACTIONS.FORWARD)
    }
    if (isTextMessage(msg)) {
      list.push({ text: formatMessage({ id: 'CHAT.COPY_MESSAGE_TEXT' }), action: ACTIONS.COPY_TEXT, icon: 'chat-copy' })
      const links = linkify.find(msg.text)
      if (links.length) {
        list.push({
          text: formatMessage({ id: 'CHAT.COPY_MESSAGE_LINK' }),
          action: ACTIONS.COPY_LINK,
          icon: 'chat-link'
        })
      }
    }
    if (['file', 'audio', 'video'].includes(msg.type)) {
      list.push({ text: formatMessage({ id: 'FILE_LOAD' }), action: ACTIONS.LOAD, icon: 'chat-download' })
    }
    if (isOwner || isAdmin || isMineMessage(msg) || (!isMineMessage(msg) && isPrivateChat())) {
      list.push({ text: formatMessage({ id: 'CHAT.DELETE_MESSAGE' }), action: ACTIONS.DELETE, icon: 'chat-remove' })
    }
    if (isTextMessage(msg) && !msg.isTranslated && !msg.isTranslating) {
      list.push(
        {
          text: formatMessage({ id: 'CHAT.TRANSLATION_MESSAGE' }),
          action: ACTIONS.TRANSLATION,
          SvgElement: TranslateImage,
        })
    }
    if (!isCallMessage(msg) && (isPrivateChat() || (isGroupChat() && (isOwner || isAdmin)))) {
      if (activeChat?.pin?.uid === msg.id) {
        list.push({ text: formatMessage({ id: 'CHAT.UNPIN' }), action: ACTIONS.UNPIN, icon: 'chat-pin' })
      } else {
        list.push({ text: formatMessage({ id: 'CHAT.PIN' }), action: ACTIONS.PIN, icon: 'chat-pin' })
      }
    }
    const actionsDisabled = []
    if (msg.status === 'sending') {
      actionsDisabled.push(...list.map(item => item.action))
    }
    showContextMenu({
      position,
      componentProps: {
        list,
        message: msg,
        actionsDisabled,
        onClick: onClickContextMenu,
      },
    })
  }

  const onScroll = (e: UIEvent) => {
    const oldScrollBottom = scrollBottom.current
    if (scrollBarRef.current
      && Math.abs(scrollHeight.current - scrollBarRef.current.getScrollHeight()) < 1
    ) {
      scrollBottom.current = getScrollBottom()
    }

    checkScrollBtnVisible()

    if (newsChat || !messages.isLoaded) {
      return
    }

    if (scrollBottom.current > oldScrollBottom && activeChat?.$subscription !== 'none') {
      loadMore()
    }
  }

  const isInToday = (timestamp: number) => {
    let currentDate = Math.trunc(parseInt('' + new Date().getTime()))
    let checkDate = Math.trunc(parseInt('' + new Date(timestamp).getTime()) / 1000)
    return new Date(currentDate).setHours(0, 0, 0, 0) === new Date(checkDate).setHours(0, 0, 0, 0)
  }

  const diffDates = (dateNew: number, dateOld: number) => {
    let dateNewVal = Math.trunc(parseInt('' + new Date(dateNew).getTime()) / 1000)
    let dateOldVal = Math.trunc(parseInt('' + new Date(dateOld).getTime()) / 1000)
    return new Date(dateNewVal).setHours(0, 0, 0, 0) === new Date(dateOldVal).setHours(0, 0, 0, 0)
  }

  useEffect(() => {
    let messageOld: ShowedMessage | INews | undefined
    const chatsWithDate: (ShowedMessage | IMessageDate | INews)[] = []

    chatMessages.forEach((val, index) => {
      if (index === 0 && !isInToday(val.timestamp)) {
        chatsWithDate.push({ date: new Date(Math.trunc(val.timestamp) / 1000) })
      }
      if (messageOld !== undefined && !diffDates(val.timestamp, messageOld.timestamp)) {
        chatsWithDate.push({ date: new Date(Math.trunc(val.timestamp) / 1000) })
      }
      chatsWithDate.push(val)
      messageOld = val
    })

    setChatMessagesWithDate(chatsWithDate)
  }, [chatMessages])

  const onReplyClick = useCallback(async(msg: IMessage): Promise<void> => {
    if (!activeChat) {
      return
    }
    const messageIndex = safeUpdatedMessages.findIndex(message => 'id' in message && message.id === msg.id)
    const htmlMessage = ~messageIndex ? messagesRef.current[messageIndex] : null
    if (htmlMessage) {
      setDisableLoading(true)
      htmlMessage.scrollIntoView({ block: 'center', behavior: 'smooth' })
      highlightMessage(htmlMessage)
      return Promise.resolve()
    }
    try {
      const historyMessages = await getHistoryMessages({
        id: getChatId(activeChat?.$jid),
        isRoom: activeChat?.type === 'groupchat',
        limit: LOAD_AFTER_FINDING_LIMIT,
        start: msg.timestamp,
      })
      dispatch(updateWithBreak({
        messages: historyMessages.map(message => fromHistoryToMessage(message)),
        chatJid: activeChat?.$jid,
      }))
      const emptyFunc = () => {
      }
      let resolve: () => void = emptyFunc
      let reject: (reason?: string) => void = emptyFunc
      const promise: Promise<void> = new Promise((res, rej) => {
        resolve = res
        reject = rej
      })
      clearTimeout(findMessage.current?.timerId)
      const timeoutFunc = () => {
        reject('timeout')
        findMessage.current = undefined
      }
      const timerId = setTimeout(timeoutFunc, FIND_MESSAGE_TIMER)
      findMessage.current = {
        resolve,
        timerId,
        messageId: msg.id,
      }
      return promise
    } catch (e) {
      return Promise.reject(e)
    }
  }, [safeUpdatedMessages, activeChat, dispatch])

  useImperativeHandle(ref, () => {
    return { replyClick: onReplyClick }
  })

  const contextValue = useMemo(() => ({
    onReplyClick,
    disableLoading,
  }), [onReplyClick, disableLoading])

  const goToFindMessage = (messageElement: HTMLElement) => {
    setTimeout(() => {
      setDisableLoading(true)
      messageElement?.scrollIntoView({ block: 'center' })
      highlightMessage(messageElement)
    }, 50)
    setDisableLoading(true)
    const findingMessage = findMessage.current
    if (!findingMessage) {
      return
    }
    findingMessage?.resolve()
    clearTimeout(findingMessage.timerId)
    findMessage.current = undefined
  }

  const handleChatMessageRef = ({ element, index, id }: {
    element: HTMLElement | null,
    index: number
    id: string
  }) => {
    messagesRef.current[index] = element
    if (id === findMessage.current?.messageId && element) {
      goToFindMessage(element)
    } else if (safeUpdatedMessages.length) {
      scrollToFirstUnreadMessage(safeUpdatedMessages)
    }
  }

  useEffect(() => {
    setMembers(getOrCreateArray<IGroupChatMember>(activeChat?.members))
  }, [activeChat?.members])

  useEffect(() => {
    setIsOwner(checkOwner(user?.$jid || '', members))
    setIsAdmin(checkAdmin(user?.$jid || '', members))
  }, [members, user?.$jid])

  return <div className={classNames(styles.box, className)} style={{ flex: 1 }}>
    <ChatMessagesContext.Provider value={contextValue}>
      <Scrollbars
        ref={scrollBarRef}
        onScroll={onScroll}
        onScrollStop={onScrollStopRef.current}
        onScrollStart={onScrollStart}
      >
        <ul
          ref={messageListRef}
          className={styles.messages}>
          {safeUpdatedMessages.map((message, i) => {
            return isDateMessage(message) ? <DateMessage
                key={message.date.getTime()}
                TagName={'li'}
                date={message.date}
              /> :
              isBreakMessage(message) ?
                <BreakMessage
                  key={message.id}
                  TagName={'li'}
                  message={message} />
                : <ChatMessage
                  ref={el => {
                    handleChatMessageRef({
                      element: el,
                      index: i,
                      id: message.id,
                    })
                  }}
                  TagName="li"
                  message={message}
                  key={message.id}
                  onContextMenu={!isNews(message) ? (e) => {
                    onContextMenu(e, message)
                  } : undefined}
                />

          })}
        </ul>
      </Scrollbars>
    </ChatMessagesContext.Provider>
    {scrollButtonVisible && <IconButton
      className={styles.scrollBtn}
      onClick={scrollToBottomAndReadMessages}
    >
      <i className={'chat-arrow-d'} />
    </IconButton>}
    <ContextMenu />
    {visibleForwardModal && <Forward
      hide={hideForwardModal}
      onSend={handleForwardMessage}
    />}
    {visibleConfirmModal && <Confirm hide={hideConfirmModal} {...confirmProps} />}
  </div>
})

export default ChatMessages;
