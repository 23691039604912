import { CustomWindow, IAuthData, ICallers } from '@/interfaces/general';
import { TypeLang } from '@/store/language/language';

declare let window: CustomWindow

export const isApplication = () => {
  return !!window.webkit || !!window.Android
}

export const copyTextToClipboard = (text: string) => {
  window.navigator.clipboard.writeText(text)
  window.Android?.onCopyToClipboard(text)
}

export const callToApp = (callers: ICallers) => {
  if (!isApplication()) {
    return
  }
  if (window.webkit?.messageHandlers) {
    window.webkit.messageHandlers.call?.postMessage(callers)
  } else if (window.Android) {
    window.Android.call?.(JSON.stringify(callers))
  }
}

export const sendTokenToApp = (token: string) => {
  if (!isApplication()) {
    return
  }
  if (window.webkit?.messageHandlers) {
    window.webkit.messageHandlers.authToken?.postMessage(token)
  } else if (window.Android) {
    window.Android.authToken?.(token)
  }
}

export const sendAccessTokenToApp = (data: IAuthData) => {
  if (!isApplication()) {
    return
  }
  if (window.webkit?.messageHandlers) {
    window.webkit.messageHandlers.saveAccessToken?.postMessage(data)
  } else if (window.Android) {
    window.Android.saveAccessToken?.(JSON.stringify(data))
  }
}

export const removeAccessTokenFromApp = () => {
  if (!isApplication()) {
    return
  }
  if (window.webkit?.messageHandlers) {
    window.webkit.messageHandlers.removeAccessToken?.postMessage('')
  } else if (window.Android) {
    window.Android.removeAccessToken?.('')
  }
}

export const getAppVersion = async(): Promise<string> => {
  if (window.Android) {
    if (window.Android.getAppVersion) {
      return (window.Android.getAppVersion())
    } else {
      return ('android:2.0.4')
    }
  }

  if (window.webkit) {
    if (window.webkit.messageHandlers?.versionHandler) {
      try {
        return await window.webkit.messageHandlers.versionHandler.postMessage('')
      } catch (e) {
        return 'ios:error'
      }
    } else {
      return 'ios:1.0.9'
    }
  }
  return ('web')
}

export const sendLanguageToApp = (lang: TypeLang) => {
  if (!isApplication()) {
    return
  }
  if (window.webkit?.messageHandlers?.localeHandler) {
    window.webkit.messageHandlers.localeHandler.postMessage(lang)
  } else if (window.Android?.setLanguage) {
    window.Android.setLanguage(lang)
  }
}
