import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '@/store';

interface Scroll {
  messagesScroll: {
    [jid: string]: number
  }
  chatScroll: number
  contactScroll: number
  teamScroll: number
}

const initialState: Scroll = {
  messagesScroll: {},
  chatScroll: 0,
  contactScroll: 0,
  teamScroll: 0
}

interface UpdateScrollProps {
  payload: number
}

const scroll = createSlice({
  name: 'scroll',
  initialState,
  reducers: {
    updateMessageScroll(state, {payload}: { payload: { jid: string, value: number } }) {
      const {jid, value} = payload
      state.messagesScroll[jid] = value
    },
    updateChatScroll(state, {payload}: UpdateScrollProps) {
      state.chatScroll = payload
    },
    updateContactScroll(state, {payload}: UpdateScrollProps) {
      state.contactScroll = payload
    },
    updateTeamScroll(state,  {payload}: UpdateScrollProps) {
      state.teamScroll = payload
    },
    clear(state) {
      state.messagesScroll = {}
      state.chatScroll = 0
      state.contactScroll = 0
      state.teamScroll = 0
    }
  },
})

export const {
  updateMessageScroll,
  updateChatScroll,
  updateContactScroll,
  updateTeamScroll,
  clear
} = scroll.actions
export const getMessageScroll = (jid: string) =>
  (state: RootState): number | undefined => state.scroll.messagesScroll[jid]
export const getChatScroll = (state: RootState) => state.scroll.chatScroll
export const getContactScroll = (state: RootState) => state.scroll.contactScroll
export const getTeamScroll = (state: RootState) => state.scroll.teamScroll
export default scroll.reducer
