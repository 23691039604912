import { TypeLang } from '@/store/language/language';

export type Nothing = Record<string, never>;

export type LoadState = 'unload' | 'loaded' | 'loading'

interface CustomHistory extends History {
  onpushstate?: (data: any, unused: string, url?: string | URL | null) => void
}

export enum CacheEvent {
  UPDATED_CHATS_CACHE_EVENT = 'UPDATED_CHATS_CACHE_EVENT',
  UPDATED_LAST_MESSAGE_CACHE_EVENT = 'UPDATED_LAST_MESSAGE_CACHE_EVENT'
}

export enum CustomEvents {
  MESSAGE_SENT_TO_CURRENT_CHAT = 'messageSentToCurrentChat',
  SHARE_FILE = 'shareFile',
  WATCHER_UPDATED_EVENT = 'WATCHER_UPDATED_EVENT',
  VIDEO_RESIZE_EVENT = 'VideoResizeEvent'
}

export type ObserverEvent = [
  entries: ResizeObserverEntry[],
  observer: ResizeObserver,
  entriesMap: Map<string, ResizeObserverEntry>
]

export type IShareFileEvent = {
  name: string
  path: string
  file?: never
} | {
  path?: never
  name?: never
  file: File
}

interface CustomEventMap {
  [CustomEvents.SHARE_FILE]: (ev: CustomEvent<IShareFileEvent>) => void
  [CustomEvents.MESSAGE_SENT_TO_CURRENT_CHAT]: () => void
  [CustomEvents.WATCHER_UPDATED_EVENT]: (ev: CustomEvent<{name: string, uid: string}>) => void
  [CustomEvents.VIDEO_RESIZE_EVENT]: (ev: CustomEvent<ObserverEvent>) => void
}

export interface ICallers {
  from: string
  to: string
  nick: string
  url?: string
}

export interface IAuthData {
  memberId: string
  accessToken: string
}

interface Android {
  onLoaded: () => void
  onCopyToClipboard: (text: string) => void
  authToken?: (token: string) => void
  call?: (callers: string) => void
  saveAccessToken?: (data: string) => void
  removeAccessToken?: (str: '') => void
  getAppVersion?: () => string
  setLanguage?: (lang: TypeLang) => void
}

interface iOS {
  messageHandlers?: {
    youchatMessageHandler: {
      postMessage: (message: string) => void
    },
    authToken?: {
      postMessage: (token: string) => void
    },
    call?: {
      postMessage: (callers: ICallers) => void
    },
    saveAccessToken?: {
      postMessage: (data: IAuthData) => void
    },
    removeAccessToken?: {
      postMessage: (str: '') => void
    },
    versionHandler?: {
      postMessage: (str: '') => Promise<string>
    },
    localeHandler?: {
      postMessage: (lang: TypeLang) => void
    }
  }
}

export interface CustomWindow extends Window {
  readonly history: CustomHistory
  Android?: Android
  webkit?: iOS

  addEventListener<K extends keyof CustomEventMap>(
    type: K,
    listener: CustomEventMap[K],
    options?: boolean | AddEventListenerOptions): void;

  removeEventListener<K extends keyof CustomEventMap>(
    type: K,
    listener: CustomEventMap[K],
    options?: boolean | AddEventListenerOptions): void;
}

export interface IClass {
  className?: string
}
